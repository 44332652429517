<style>
  .container-fluid {
    /* background: rgb(33,150,243);
  background: radial-gradient(circle, rgba(33,150,243,1) 0%, rgba(33,95,243,1) 50%, rgba(69,120,252,1) 100%); */
    background-color: #fff !important;

    background-image: url('assets/img/bg1.png');
    background-size: cover;

    /* background-color: #18468e !important; */
  }

  .container {
    background-color: #2196f3 !important;
  }
</style>
<div class="container-fluid pt-0 pb-3">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10">
      <div>
        <img *ngIf="cotizacion?.archivo_url" [src]="cotizacion?.archivo_url" class="img-fluid w-100">
      </div>
      <div class="card">
        <div [style.backgroundColor]="cotizacion?.empresa_color_principal" class="row m-0">
          <h1 class="col-sx-12 col-md-5">
            <span *ngIf="cotizacion?.empresa_logo">
              <img class="mt-3 img-fluid" [src]="cotizacion.empresa_logo" />
            </span>
          </h1>
          <div class="col-sx-12 col-md-7" style="color: white;">
            <div class="font-weight-bold" style="font-size: 1.4em;">Tarjeta de Reserva #{{cotizacion?.consecutivo}}</div>
            <div class="font-weight-bold" style="font-size: 1.2em;">{{cotizacion?.empresa?.direccion | titlecase}}
            </div>
            <div>
              <small style="font-size: 1.1em;">Celular: {{cotizacion?.empresa?.telefono | titlecase}}</small>
            </div>
            <small style="font-size: 1.1em;">Email: {{cotizacion?.empresa?.email | titlecase}}</small>
          </div>
        </div>
        <hr>
        <div class="table-responsive m-2">
          <table class="table table-borderless">
            <tbody>
              <tr class="">
                <td>
                  <h5><i class="fa fa-file-invoice-dollar"></i> Facturar a</h5>
                </td>
                <td></td>
              </tr>
              <tr class="content">
                <td class="font-weight-bold">Nombre: {{cotizacion?.cliente?.nombre | titlecase}} <br>Dirección:
                  {{cotizacion?.cliente?.direccion | titlecase}} <br>Cedula: {{cotizacion?.cliente?.cc |
                  titlecase}}</td>
                <td class="font-weight-bold">Telefono: {{cotizacion?.cliente?.telefono | titlecase}} <br>Celular:
                  {{cotizacion?.cliente?.celular | titlecase}} <br>Email: {{cotizacion?.cliente?.email |
                  titlecase}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <hr>
        <div class="products p-2">


          <!-- <div class="products p-2">
            <table class="table table-borderless">
              <tbody>
                <tr class="add">
                  <td>Valor Venta</td>
                  <td class="">Tipo Pasajero</td>
                  <td class="text-right">Valor</td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Adulto ({{tarjeta_reserva?.adulto}})</td>
                  <td class="text-right" style="font-size: 1.5em;">$ <span
                      *ngIf="tarjeta_reserva?.adulto_tarifa">{{tarjeta_reserva?.adulto_tarifa | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.adulto_tarifa">0</span></td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Niño ({{tarjeta_reserva?.nino}})</td>
                  <td class="text-right" style="font-size: 1.5em;">$ <span
                      *ngIf="tarjeta_reserva?.nino_tarifa">{{tarjeta_reserva?.nino_tarifa | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.nino_tarifa">0</span></td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Infante ({{tarjeta_reserva?.infante}})</td>
                  <td class="text-right" style="font-size: 1.5em;">$ <span
                      *ngIf="tarjeta_reserva?.infante_tarifa">{{tarjeta_reserva?.infante_tarifa | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.infante_tarifa">0</span></td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Total</td>
                  <td class="text-right" style="font-size: 1.8em;">$ <span *ngIf="tarjeta_reserva?.total_venta"
                      class="text-bold">{{tarjeta_reserva?.total_venta | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.total_venta" class="text-bold">0</span></td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <hr>


          <div class="products p-2">
            <h5 class="add ml-1"><i class="fa fa-gavel"></i> POLITICAS</h5>
            <table class="table table-borderless">
              <tbody>
                <tr class="content" *ngFor="let item of cotizacion?.politicas let i=index" style="cursor: pointer;"
                  (click)="politicaVer(item)">
                  <td>{{i+1}}</td>
                  <td>{{item.nombre | titlecase}}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>


<!-- 

  <div class="modal fade" id="politicaVer" tabindex="-1" aria-labelledby="politicaVerLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Politica</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal"
            data-bs-target="#politicaVer" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h1 class="text-center mb-4 mt-4">{{politicaNombre | titlecase}}</h1>
              <div>{{politicaDescripcion}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div> -->