<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-5">
            <h1>
              Plantilla
              <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevo"><i
                  class="fa fa-plus"></i></button>
            </h1>
          </div>
        </div>

      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Plantilla</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Codigo</th>
            <th scope="col">Nombre</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of obj.items">
            <td (click)="ver(item)" style="cursor: pointer;"><span>{{item.codigo | titlecase}}</span></td>
            <td (click)="ver(item)" style="cursor: pointer;"><span>{{item.nombre | titlecase}}</span></td>
            <td>
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver Plantilla</span></li>
                  <li><span class="dropdown-item text-danger" style="cursor: pointer;"
                      (click)="eliminar(item)">Eliminar</span></li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <!-- <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="obj.current > 1">
        <button type="button" class="page-link" (click)="changePage(obj.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': obj.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="obj.current < obj.total_page">
        <button type="button" class="page-link" (click)="changePage(obj.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{obj.size}}</b></p>
    </div>
  </div> -->
  <!-- Fin paginador -->

</section>




<!-- Modal Ver  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Plantilla</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">

        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal Nueva  -->
<div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5>Nueva Plantilla</h5>
      </div>
      <div class="modal-body">
        <div class="container py-4">
          <div class="row">
            <!-- Panel de Elementos -->
            <div class="col-2">
              <h6 class="mt-4">Elementos</h6>
              <div *ngFor="let element of elements" class="mb-2">
                <button class="btn btn-outline-secondary w-100" (click)="addElementToZone(element)">
                  {{ element.label }}
                </button>
              </div>
            </div>

            <!-- Zona de Construcción -->
            <div class="col-10">
              <div class="row">
                <div class="form-group col-6">
                  <label for="codigo">Codigo:</label>
                  <input type="url" id="codigo" class="form-control" [(ngModel)]="codigo" name="codigo">
                </div>
                <div class="form-group col-6">
                  <label for="codigo">Nombre:</label>
                  <input type="url" id="codigo" class="form-control" [(ngModel)]="nombre" name="codigo">
                </div>
              </div>
              <h6>Zona de Construcción</h6>
              <div id="constructionArea" class="construction-area border p-3">
                <ng-container *ngFor="let item of droppedItems; let i = index">
                  <div class="mb-2 p-2 border position-relative" draggable="true"
                    (dragstart)="onDragStart($event, item, i)" (dragover)="allowDrop($event)"
                    (drop)="onDrop($event, i)">

                    <div class="position-absolute end-0 top-0 d-flex gap-1 m-1">
                      <button class="btn btn-info btn-sm" (click)="editItem(i,item)">
                        <i class="fas fa-edit"></i>
                      </button>
                      <button class="btn btn-danger btn-sm" (click)="removeItem(i)">
                        ×
                      </button>
                    </div>


                    <ng-container [ngSwitch]="item.type">
                      <!-- Botón -->
                      {{item.id}}
                      <button *ngSwitchCase="'button'" class="{{ item.class }}">
                        {{ item.label }}
                      </button>


                      <!-- Modal -->
                      <!-- <div class="modal fade" id="{{item.id}}" [ngSwitch]="item.type == 'button'" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header bg-primary text-white">
                              <h5 class="modal-title" id="exampleModalLabel">Boton Ajustes</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Cerrar"></button>
                            </div>
                            <div class="modal-body">

                              <div class="form-group">
                                <label for="label">Etiqueta:</label>
                                <input type="text" id="label" class="form-control" [(ngModel)]="item.label" name="label" required>
                              </div>
                            
                              <div class="form-group">
                                <label for="link">Enlace:</label>
                                <input type="url" id="link" class="form-control" [(ngModel)]="item.link" name="link" required>
                              </div>                              
                            </div>

                            <div class="modal-footer bg-light text-white">
                              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cerrar</button>
                              <button type="button" class="btn btn-primary">Guardar Cambios</button>
                            </div>
                          </div>
                        </div>
                      </div> -->


                      <!-- Texto -->
                      <p *ngSwitchCase="'text'" class="{{ item.class }}">
                        {{ item.label }}
                      </p>

                      <!-- Imagen -->
                      <img *ngSwitchCase="'image'" [src]="item.src" alt="{{ item.label }}" class="img-fluid mb-2"
                        style="max-width: 100px;">

                      <!-- Grid Box -->
                      <div *ngSwitchCase="'grid-box'">
                        <div class="d-flex justify-content mb-2">
                          <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 1)">1 Col</button>
                          <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 2)">2 Col</button>
                          <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 3)">3 Col</button>
                          <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 4)">4 Col</button>
                        </div>
                        <div class="row">
                          <div class="col" *ngFor="let col of [].constructor(item.columns); let colIndex = index">
                            <div class="border p-2 mb-2" (dragover)="allowDrop($event)"
                              (drop)="onDrop($event, 0, item)">
                              <p>Columna {{ colIndex + 1 }}</p>
                              <ng-container *ngFor="let child of item.items; let childIndex = index">
                                <div class="p-2 border mb-1 draggable-item" draggable="true"
                                  (dragstart)="onDragStart($event, child, childIndex, false)">
                                  <!-- <button class="btn btn-danger btn-sm position-absolute end-0 top-0 m-1"
                                    (click)="removeItem(childIndex, item)">
                                    ×
                                  </button> -->
                                  {{ child.label }}
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>


<!-- //////// Modal button edit ////////////////////////////////////////////// -->
                <div class="modal fade" id="buttonEdit"  tabindex="-1"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header bg-primary text-white">
                        <h5 class="modal-title" id="exampleModalLabel">Boton Ajustes</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Cerrar"></button>
                      </div>
                      <div class="modal-body" *ngIf="campos?.type == 'button'">

                        <div class="form-group">
                          <label for="label">Etiqueta:</label>
                          <input type="text" id="label" class="form-control" [(ngModel)]="campos.label" name="label"
                            required>
                        </div>

                        <div class="form-group">
                          <label for="link">Enlace:</label>
                          <input type="url" id="link" class="form-control" [(ngModel)]="campos.link" name="link" required>
                        </div>
                      </div>

                      <div class="modal-footer bg-light text-white">
                        {{ campos?.index }}
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-primary" (click)="saveItem(campos, 'button')">Guardar Cambios</button>
                      </div>
                    </div>
                  </div>
                </div>

<!-- //////// Modal text edit ////////////////////////////////////////////// -->
                <div class="modal fade" id="textEdit"  tabindex="-1"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header bg-primary text-white">
                        <h5 class="modal-title" id="exampleModalLabel">Texto Ajustes</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Cerrar"></button>
                      </div>
                      <div class="modal-body" *ngIf="campos?.type == 'text'">


                        <div class="form-group">
                          <label for="content">Texto:</label>
                          <textarea id="content" class="form-control" [(ngModel)]="campos.label" name="content" rows="4" required></textarea>
                        </div>
                        
                      </div>

                      <div class="modal-footer bg-light text-white">
                        {{ campos?.index }}
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cerrar</button>
                        <button type="button" class="btn btn-primary" (click)="saveItem(campos, 'text')">Guardar Cambios</button>
                      </div>
                    </div>
                  </div>
                </div>

                {{droppedItems }}
              </div>

              <button type="button" class="btn btn-block btn-primary w-100 mt-2" (click)="guardarPlantilla()">Actualizar</button>

            </div>
          </div>
        </div>

      </div>
    </div>


  </div>
</div>