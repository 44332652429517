import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
declare var $: any;


@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.scss']
})
export class PlantillasComponent implements OnInit {
  public isAuthLoading = false;

  public user: any = null;
  reporteExport: any;
  findInput: any;
  obj: any = [];
  pagesNumber: any;
  offset = 3;
  page = 1;
  public cargando = false;

  codigo: any;
  nombre: any;

  // Elementos disponibles para arrastrar
  elements = [
    { id: 1, type: 'button', label: 'Botón Primario', class: 'btn btn-primary', link: '#', target: '_blank' },
    { id: 2, type: 'text', label: 'Texto de ejemplo', class: 'text-muted' },
    { id: 3, type: 'image', label: 'Imagen', src: 'assets/img/img-default.png' },
    // { id: 4, type: 'grid-box', label: 'Grid Box', columns: 2, items: [] } // Grid Box con capacidad de dividirse
  ];

  // Elementos agregados a la zona de construcción
  campos: any;
  droppedItems: any[] = [];
  draggedItem: any = null;
  isNewElement: boolean = false;

  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();
  }


  getList() {
    this.appService.getPlantillas(this.user.et + '-' + this.page).subscribe((data) => {
      this.obj = data['obj'];
      console.log(this.obj);
      this.ForpagesNumber();
      this.cargando = false;
    });
  }


  changePage(page) {
    this.page = page; //para el filtro
    this.obj.current = page;
    this.getList();
  }

  ForpagesNumber() {
    let from = this.obj.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.obj.total_page) {
      to = this.obj.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async new() {
    // if (this.nuevoForm.valid) {
    //     this.isAuthLoading = true; 
    //       let params = {
    //           nuevo:true,
    //           et: this.user.et,
    //           nombre: this.nuevoForm.value.nombre,
    //           descripcion: this.nuevoForm.value.descripcion,
    //           estado: 1,
    //         };
    //         // console.log(params);
    //         this.appService.postPoliticas(params)
    //       .subscribe( (data) => {
    //         // console.log(data);
    //           if (data["logger"] == true){
    //               this.nuevoForm.reset()
    //               $('#nuevo').modal('hide');
    //               this.toastr.success(data["msg"]);
    //               this.getList();
    //           }else{
    //               this.toastr.error(data["msg"]);
    //           }
    //       }); 
    //     this.isAuthLoading = false;
    // } else {
    //     this.toastr.error('El formulario no es válido!');
    // }
  }


  ver(item) {
    // console.log(item);
    // this.editForm.get('id').setValue(item.id);
    // this.editForm.get('nombre').setValue(item.nombre);
    // this.editForm.get('descripcion').setValue(item.descripcion);
    $('#ver').modal('show');
  }

  async edit() {
    //   if (this.editForm.valid) {
    //     this.isAuthLoading = true; 
    //       let params = {
    //           editar:true,
    //           id: this.editForm.value.id,
    //           nombre: this.editForm.value.nombre,
    //           descripcion: this.editForm.value.descripcion,
    //           // empresa_id: this.user.eid
    //         };
    //         // console.log(params);
    //         this.appService.postPoliticas(params)
    //       .subscribe( (data) => {
    //           if (data["logger"] == true){
    //             this.toastr.success(data["msg"]);
    //             // this.editForm.reset()
    //             $('#ver').modal('hide');
    //             this.getList();
    //           }else{
    //             this.toastr.error(data["msg"]);
    //           }
    //       }); 
    //     this.isAuthLoading = false;
    // } else {
    //     this.toastr.error('El formulario no es válido!');
    // }
  }

  async eliminar(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar al Politica?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');

        let params = {
          eliminar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postPoliticas(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });

  }


  onDragStart(event: DragEvent, item: any, index: number, isNew: boolean = false) {
    this.draggedItem = { item, index };
    this.isNewElement = isNew;
    event.dataTransfer?.setData('text', JSON.stringify(this.draggedItem));
  }

  allowDrop(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, dropIndex: number, parentItem: any = null) {
    event.preventDefault();

    if (this.draggedItem && this.draggedItem.item) {
      const { item, index } = this.draggedItem;

      // Verificar si el elemento es nuevo o si ya existe en la zona de construcción
      if (this.isNewElement) {
        // Si es un nuevo elemento desde el panel, añadir una copia del elemento
        const newItem = { ...item, id: Date.now(), items: [], columns: item.columns || 1 };
        if (parentItem && parentItem.type === 'grid-box') {
          parentItem.items.push(newItem);
        } else {
          this.droppedItems.splice(dropIndex, 0, newItem);
        }
      } else {
        // Si es un elemento existente, moverlo dentro de la zona de construcción
        if (parentItem && parentItem.type === 'grid-box') {
          parentItem.items.splice(index, 1);
          parentItem.items.splice(dropIndex, 0, item);
        } else {
          this.droppedItems.splice(index, 1);
          this.droppedItems.splice(dropIndex, 0, item);
        }
      }
    }

    // Resetear el estado
    this.draggedItem = null;
    this.isNewElement = false;
  }

  addElementToZone(item: any) {
    const newItem = { ...item, id: Date.now(), items: [], columns: 1 };
    this.droppedItems.push(newItem);
    console.log(this.droppedItems)
  }

  // Método para eliminar elementos
  removeItem(index: number, parentItem: any = null) {
    if (parentItem) {
      parentItem.items.splice(index, 1);
    } else {
      this.droppedItems.splice(index, 1);
    }
  }

  editItem(index: number, item: any = null) {
    // console.log(item);
    // if (item.type == 'button') {
      this.campos = { ...item, index: index }; // Se agrega el atributo index al objeto
      console.log(this.campos);

      $('#'+item.type+'Edit').modal('show');
      // $('#buttonEdit').modal('show');
      // $('#'+item.id).modal('show');
    // }
  }

  saveItem(item: any, elementobj: any) {
    console.log(item);
    console.log(item);
    // Si el item tiene un índice, lo usamos para actualizar el item dentro de droppedItems
    if (item.index !== undefined) {
      // Reemplazar el item en la posición indicada por el index
      this.droppedItems[item.index] = { ...item }; // Esto reemplaza el item en la posición 'index'
      console.log("Item actualizado en droppedItems:", this.droppedItems);
      $('#'+elementobj+'Edit').modal('hide');
    }
  }


  // Cambiar el número de columnas del Grid Box
  setGridColumns(item: any, columns: number) {
    item.columns = columns;
  }

  guardarPlantilla(){
      let params = {
          nuevo:true,
          et: this.user.et,
          codigo: this.codigo,
          nombre: this.nombre,
          descripcion: this.droppedItems,
          estado: 1,
        };
        // console.log(params);
        this.appService.postPlantillas(params)
      .subscribe( (data) => {
        // console.log(data);
          if (data["logger"] == true){
              $('#nuevo').modal('hide');
              this.toastr.success(data["msg"]);
              this.getList();
          }else{
              this.toastr.error(data["msg"]);
          }
      }); 
   
  }
}
