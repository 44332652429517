import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit {
  public nuevoForm: FormGroup;
  public nuevoClienteForm: FormGroup;
  public editForm: FormGroup;

  public cargando = false;
  id:any;
  findInput: any;
  cotizaciones: any = [];
  pagesNumber: any;
  offset = 3;
  page = 1;
  public user: any = null;
  buscador: any;
  listClientes: any[];
  listPoliticas: any[];
  listPoliticasCotizacion: any[];
  cotizacion_id: any;
  consecutivo_id: any;

  listIata: any[];

  origen: string;
  destino: string;
  partida: string;
  regreso: string;
  titulo: string;
  descripcion: string;
  cotizacion_item_costeo: any = [];
  nuevoItem: any = [];
  // precioCotizacion: number = 0; // Variable para almacenar el valor total

  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();
    this.getListIata();
    this.getListClientes();
    this.getListPoliticas();

    this.nuevoForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.nullValidator),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
      nota: new FormControl(null, Validators.nullValidator),
    });

    this.nuevoClienteForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      cliente_id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
    });


  }

  getList() {
    this.cargando = true;
    this.findInput = null;
    setTimeout(() => this.appService.getCotizacion(this.user.et + '-' + this.page).subscribe((data) => {
      console.log(data['obj']);
      this.cotizaciones = data['obj'];
      this.ForpagesNumber();
      this.cargando = false;
    }), 400);
  }

  getListIata() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postIata(params).subscribe((data) => {
      this.listIata = data['iata'];
      // console.log(this.listIata);   
      this.cargando = false;
    });
  }

  selectVueloOrigen(item) {
    this.origen = item.codigo;
    $('#iataOrigen').hide();
  }

  selectVueloDestino(item) {
    this.destino = item.codigo;
    $('#iataDestino').hide();
  }

  agregarProducto() {
  }

  // Agregar una nueva línea
  agregarLinea() {
    if (this.nuevoItem.detalle.trim() !== '' && this.nuevoItem.valor !== null) {
      // Agregar el nuevo ítem a la lista
      this.cargando = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        ut: this.user.token,
        id: this.editForm.value.id,
        detalle: this.nuevoItem.detalle,
        valor: this.nuevoItem.valor,
        estado: 1,
      };
      console.log(params);
      this.appService.postCotizacionItemCosteo(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            this.cotizacion_item_costeo = data["data"];
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.cargando = false;

      // Limpiar el formulario para el próximo ítem
      this.nuevoItem = { detalle: '', valor: null };
    } else {
      alert('Por favor, complete todos los campos antes de agregar.');
    }
    // $('#editPrecioCotizacion').hide();

    console.log(this.cotizacion_item_costeo);
  }

  // Eliminar una línea
  eliminarLinea(index: number) {
    // this.cotizacion_item_costeo.splice(index, 1);

    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar esta opción de precio?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log('dentro true');
        this.cargando = true;
        let params = {
          eliminar: true,
          et: this.user.et,
          ut: this.user.token,
          id: index,
        };
        console.log(params);
        this.appService.postCotizacionItemCosteo(params)
          .subscribe((data) => {
            // console.log(data);
            if (data["logger"] == true) {
              this.cotizacion_item_costeo = data["data"];
              this.getList();
            } else {
              this.toastr.error(data["msg"]);
            }
          });
        this.cargando = false;

      }
    });


  }

  // Calcular el total de cotización
  // calcularTotal() {
  //   this.precioCotizacion = this.cotizacion_item_costeo.reduce(
  //     (total, item) => total + (item.valor || 0),
  //     0
  //   );
  //   console.log('Total Cotización:', this.precioCotizacion);
  // }

  createPrecioCotizacion() {
    this.nuevoItem = { detalle: '', valor: null }
    if (this.editForm) {
      $('#editPrecioCotizacion').show();
    } else {
      $('#editPrecioCotizacion').show();
    }
  }


  ForpagesNumber() {
    let from = this.cotizaciones.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.cotizaciones.total_page) {
      to = this.cotizaciones.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.cotizaciones.current = page;
    this.getList();
  }

  ver(item) {
    console.log(item);
    this.cotizacion_id = item.id;
    this.consecutivo_id = item.consecutivo;
    // this.tarjetaEditar = true;
    // this.selectPasajeros = null;
    // this.selectVuelos = null;
    this.cotizacion_item_costeo = null;

    this.editForm.get('id').setValue(item.id);
    this.editForm.get('cliente_id').setValue(item.cliente_id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('email').setValue(item.email);
    this.editForm.get('cc').setValue(item.cc);
    this.editForm.get('direccion').setValue(item.direccion);
    this.editForm.get('celular').setValue(item.celular);
    this.editForm.get('ciudad').setValue(item.ciudad);

    this.titulo = item.cotizacion_item?.titulo;
    this.origen = item.cotizacion_item?.origen;
    this.destino = item.cotizacion_item?.destino;
    this.partida = item.cotizacion_item?.fechain;
    this.regreso = item.cotizacion_item?.fecha_out;
    this.descripcion = item.cotizacion_item?.descripcion;

    this.cotizacion_item_costeo = item.cotizacion_item_costeo;
    // this.tarjeta_reserva_id = item.id;

    this.getListPoliticasCotizacion(item.id);

    // this.selectCAcomodacion = item.acomodacion;

    $('#ver').modal('show');
    $('#editPrecioCotizacion').hide();
    this.nuevoItem = { detalle: '', valor: null };
  }

  abrirModalActualizarEstado(item){
    this.id = item.id;
    console.log(this.id);
    $('#actualizarEstadoModal').modal('show');
  }

  actualizarEstado(estado){
    console.log(this.id);
    this.cargando = true;
    let params = {
      editEstado: true,
      et: this.user.et,
      id: this.id,
      estado: estado
    };
    this.appService.postCotizacion(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          $('#actualizarEstadoModal').modal('hide');
          this.getList();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
    this.cargando = false;    
  }

  getBuscarCliente() {
    let params = {
      buscar: true,
      buscar_input: this.buscador,
      et: this.user.et,
    };
    this.appService.postClientes(params).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      // console.log(this.listIata);   
      this.cargando = false;
    });
  }

  getListClientes() {
    this.appService.getClientes(this.user.et).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      this.cargando = false;
    });
  }

  async newCliente() {
    if (this.nuevoClienteForm.valid) {
      this.cargando = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoClienteForm.value.nombre,
        cc: this.nuevoClienteForm.value.cc,
        celular: this.nuevoClienteForm.value.celular,
        direccion: this.nuevoClienteForm.value.direccion,
        email: this.nuevoClienteForm.value.email,
        et: this.user.et,
      };
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.nuevoForm.get('id').setValue(data["data"].id);
            this.nuevoForm.get('nombre').setValue(data["data"].nombre);
            this.nuevoForm.get('cc').setValue(data["data"].cc);
            this.nuevoForm.get('email').setValue(data["data"].email);
            this.nuevoForm.get('direccion').setValue(data["data"].direccion);
            this.nuevoForm.get('celular').setValue(data["data"].celular);
            // this.router.navigate(['/']);
            this.nuevoClienteForm.reset()
            $('#crearCliente').hide();
            this.getListClientes();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.cargando = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  createCliente(buscador) {
    this.buscador = null;
    $('#crearCliente').show();
  }

  selectCliente(item) {
    this.buscador = null;
    this.nuevoForm.get('id').setValue(item.id);
    this.nuevoForm.get('nombre').setValue(item.nombre);
    this.nuevoForm.get('cc').setValue(item.cc);
    this.nuevoForm.get('email').setValue(item.email);
    this.nuevoForm.get('direccion').setValue(item.direccion);
    this.nuevoForm.get('celular').setValue(item.celular);
    // console.log(item);
  }

  async nuevoCotizacion() {
    if (this.nuevoForm.valid) {
      this.cargando = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        ut: this.user.token,
        id: this.nuevoForm.value.id,
        estado: 1,
      };
      console.log(params);
      this.appService.postCotizacion(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            // console.log(this.nuevoForm.value);
            let params = {
              id: data["data"].id,
              cliente_id: this.nuevoForm.value.cliente_id,
              nombre: this.nuevoForm.value.nombre,
              email: this.nuevoForm.value.email,
              cc: this.nuevoForm.value.cc,
              direccion: this.nuevoForm.value.direccion,
              celular: this.nuevoForm.value.celular,
              ciudad: this.nuevoForm.value.ciudad,
            }
            this.ver(params);
            this.nuevoForm.reset()
            $('#nuevo').modal('hide');
            $('#ver').modal('show');

            this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.cargando = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  editarCotizacion() {
    if (this.editForm.valid) {
      this.cargando = true;
      let params = {
        editar: true,
        et: this.user.et,
        ut: this.user.token,
        id: this.editForm.value.id,
        cliente_id: this.editForm.value.cliente_id,
        origen: this.origen,
        partida: this.partida,
        regreso: this.regreso,
        destino: this.destino,
        titulo: this.titulo,
        descripcion: this.descripcion,
        // cotizacion_item:this.cotizacion_item_costeo,
        estado: 1,
      };
      console.log(params);
      this.appService.postCotizacion(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            // let params = {
            //   id: data["data"].id,
            //   cliente_id: this.nuevoForm.value.cliente_id,
            //   nombre: this.nuevoForm.value.nombre,
            //   email: this.nuevoForm.value.email,
            //   cc: this.nuevoForm.value.cc,
            //   direccion: this.nuevoForm.value.direccion,
            //   celular: this.nuevoForm.value.celular,
            //   ciudad: this.nuevoForm.value.ciudad,
            // }
            // this.ver(params);
            // this.editForm.reset()
            // $('#nuevo').modal('hide');
            // $('#ver').modal('show');

            this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.cargando = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }

  }
  findCotizacion() { }

  resetAll() { }

  getListPoliticas() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      this.listPoliticas = data['obj'];
      this.cargando = false;
    });
  }

  addPolitica(item) {
    console.log(item);
    let params = {
      addCotizacion: true,
      et: this.user.et,
      id: item.id,
      cotizacion_id: this.cotizacion_id
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      this.getListPoliticasCotizacion(this.cotizacion_id);
      this.cargando = false;
    });

  }

  deletePolitica(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar esta Politica?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {

      let params = {
        deletePoliticaCotizacion: true,
        et: this.user.et,
        id: item.id,
        cotizacion_id: this.cotizacion_id
      };
      this.appService.postPoliticas(params).subscribe((data) => {
        this.getListPoliticasCotizacion(this.cotizacion_id);
        this.cargando = false;
      });
    });

  }

  getListPoliticasCotizacion(id) {
    let params = {
      getPoliticaCotizacion: true,
      et: this.user.et,
      id: id
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      this.listPoliticasCotizacion = data['obj'];
      this.cargando = false;
    });
  }
}
