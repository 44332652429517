import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import tinymce from 'tinymce/tinymce';

import Swal from 'sweetalert2';

declare var $: any;


@Component({
  selector: 'app-visas',
  templateUrl: './visas.component.html',
  styleUrls: ['./visas.component.scss']
})
export class VisasComponent implements OnInit {
  listFormato: any[];
  page = 1;
  pageInteresados = 1;
  pageEnProceso = 1;
  pageAgendado = 1;
  pageFinalizado = 1;
  pagesNumber: any;
  pagesNumberEnProceso: any;
  pagesNumberAgendado: any;
  pagesNumberFinalizado: any;
  offset = 3;
  public user: any = null;

  public nuevoForm: FormGroup;
  public nuevoClienteForm: FormGroup;

  public editForm: FormGroup;
  public loadingImg = false;
  public isAuthLoading = false;
  public cargando = false;

  whatsappNumero: any;
  selectFormato: any;

  buscador: any;
  listClientes: any[];
  listInteresados: any = [];
  listEnProceso: any= [];
  listAgendado: any= [];
  listFinalizado: any= [];
  clienteSeleccionado: any;
  VisaSeleccionada: any;

  visa_id: any;
  cliente_id: any;
  cliente_celular: any;
  estado_solicitud: any;
  adjunto_nombre: any;
  adjunto_archivo: any;
  adjunto_archivo_url: any;
  adjunto_archivo_extension: any;

  adjunto_archivo_formato: any;

  detalle_historial: any;
  foto: boolean = false;
  pasaporte: boolean = false;
  formulario: boolean = false;

  personas: any;
  valor_servicio: any;
  valor_total: any;
  listAdjuntosVisas: any[] = [];  // Inicializa como un array vacío
  fecha_cita1: any;
  fecha_cita2: any;


  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;

    this.nuevoForm = new FormGroup({
      nombre: new FormControl(null, Validators.nullValidator),
      requisitos: new FormControl(null, Validators.nullValidator),
      entrevista: new FormControl(null, Validators.nullValidator),
      formulario: new FormControl(null, Validators.nullValidator),
      formulario_url: new FormControl(null, Validators.nullValidator),
      costo: new FormControl(null, Validators.nullValidator),
      costo_servicio: new FormControl(null, Validators.nullValidator),
      img: new FormControl(null, Validators.nullValidator),
      img_url: new FormControl(null, Validators.nullValidator),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.nullValidator),
      requisitos: new FormControl(null, Validators.nullValidator),
      entrevista: new FormControl(null, Validators.nullValidator),
      formulario: new FormControl(null, Validators.nullValidator),
      formulario_url: new FormControl(null, Validators.nullValidator),
      costo: new FormControl(null, Validators.nullValidator),
      costo_servicio: new FormControl(null, Validators.nullValidator),
      img: new FormControl(null, Validators.nullValidator),
      img_url: new FormControl(null, Validators.nullValidator),
    });

    this.getListFormato();
    this.getListClientes();
    this.getListVisas();
  }

  getListFormato() {
    this.appService.getVisaFormato(this.user.et).subscribe((data) => {
      this.listFormato = data['obj'];
      // console.log(this.listFormato);
    });
  }

  nuevoFormato() {
    if (this.nuevoForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoForm.value.nombre,
        requisitos: this.nuevoForm.value.requisitos,
        entrevista: this.nuevoForm.value.entrevista,
        formulario: this.nuevoForm.value.formulario,
        costo: this.nuevoForm.value.costo,
        costo_servicio: this.nuevoForm.value.costo_servicio,
        img: this.nuevoForm.value.img,
        et: this.user.et,
      };
      // console.log(params);
      this.appService.postVisaFormato(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.nuevoForm.reset()
            $('#crearformato').modal('hide');
            this.getListFormato();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }    
  }

  deleteFormato(id) {
    this.isAuthLoading = true;
    let params = {
      delete: true,
      id: id
    };

    this.appService.postVisaFormato(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.toastr.success(data["msg"]);
          $('#editarformato').modal('hide');
          this.getListFormato();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
    this.isAuthLoading = false;

  }

  deleteVisa(id) {
    this.isAuthLoading = true;
    Swal.fire({
      title: '¿Está seguro?',
      html: 'Desea eliminar la solicitud de visa</b> ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {

        let params = {
          delete: true,
          id: id
        };

        // console.log(params);
        this.appService.postVisaHistorial(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.toastr.success(data["msg"]);
              this.getListVisasFinalizado();
            } else {
              this.toastr.error(data["msg"]);
            }
          });
      }
    });

    this.isAuthLoading = false;

  }

  ver(item) {
    console.log(item);
    this.editForm.get('id').setValue(item.id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('requisitos').setValue(item.requisitos);
    this.editForm.get('entrevista').setValue(item.entrevista);
    this.editForm.get('formulario').setValue(item.formulario);
    this.editForm.get('formulario_url').setValue(item.formulario_url);

    this.editForm.get('costo').setValue(item.costo);
    this.editForm.get('costo_servicio').setValue(item.costo_servicio);
    this.editForm.get('img').setValue(item.img);
    this.editForm.get('img_url').setValue(item.img_url);
    $('#editarformato').modal('show');
    // console.log(this.editForm);
  }

  editFormato() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editForm.value.id,
        nombre: this.editForm.value.nombre,
        requisitos: this.editForm.value.requisitos,
        entrevista: this.editForm.value.entrevista,
        formulario: this.editForm.value.formulario,
        costo: this.editForm.value.costo,
        costo_servicio: this.editForm.value.costo_servicio,
        img: this.editForm.value.img,
      };
      // console.log(params);
      this.appService.postVisaFormato(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.editForm.reset()
            $('#editarformato').modal('hide');
            this.getListFormato();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  selectFormatoSend(item) {
    // console.log(item);
    this.selectFormato = item;
  }

  selectCliente(item) {
    this.buscador = null;
    this.clienteSeleccionado = item;
    // console.log(item);
  }

  createCliente(buscador) {
    this.buscador = null;
    $('#crearCliente').show();
  }

  async newCliente() {
    if (this.nuevoClienteForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoClienteForm.value.nombre,
        cc: this.nuevoClienteForm.value.cc,
        celular: this.nuevoClienteForm.value.celular,
        direccion: this.nuevoClienteForm.value.direccion,
        email: this.nuevoClienteForm.value.email,
        et: this.user.et,
      };
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.nuevoForm.get('id').setValue(data["data"].id);
            this.nuevoForm.get('nombre').setValue(data["data"].nombre);
            this.nuevoForm.get('cc').setValue(data["data"].cc);
            this.nuevoForm.get('email').setValue(data["data"].email);
            this.nuevoForm.get('direccion').setValue(data["data"].direccion);
            this.nuevoForm.get('celular').setValue(data["data"].celular);
            // this.router.navigate(['/']);
            this.nuevoClienteForm.reset()
            $('#crearCliente').hide();
            this.getListClientes();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  getListClientes() {
    this.appService.getClientes(this.user.et).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      this.cargando = false;
    });
  }

  crearSolicitud() {
    let params = {
      nuevoVisa: true,
      cliente_id: this.clienteSeleccionado.id,
      visa_id: this.selectFormato.id,
      valor_servicio: this.selectFormato.costo_servicio,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaFormato(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.clienteSeleccionado = null;
          this.selectFormato = null;
          $('#offcanvasNuevaSolicitud').offcanvas('hide');
          this.getListVisas();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  preview(files, componente) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;
      //   console.log("esta es la imagen"+imgURL);

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'visa-formato',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
          .subscribe((data) => {
            if (data['logger'] == true) {
              if (componente == 'nuevo') {
                this.nuevoForm.get('img').setValue(data['filename']);
                this.nuevoForm.get('img_url').setValue(data['fileUrl']);
              }
              if (componente == 'editar') {
                this.nuevoForm.get('img').setValue(data['filename']);
                this.nuevoForm.get('img_url').setValue(data['fileUrl']);
              }
              this.getListFormato();

            }
            this.loadingImg = false;
          });
      }
    }

  }

  getListVisas() {
    let params = {
      get: true,
      estado: 1,
      page: this.pageInteresados,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.listInteresados = data['obj'];
          this.ForpagesNumber();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  changePage(page) {
    this.pageInteresados = page; //para el filtro
    this.listInteresados.current = page;
    this.getListVisas();
  }


  ForpagesNumber() {
    let from = this.listInteresados.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.listInteresados.total_page) {
      to = this.listInteresados.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }  

  getListVisasEnproceso() {
    let params = {
      getEnproceso: true,
      estado: 1,
      page: this.pageEnProceso,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.listEnProceso = data['obj'];
          this.ForpagesNumberListEnProceso();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  changePageListProceso(page) {
    this.pageEnProceso = page; //para el filtro
    this.listEnProceso.current = page;
    this.getListVisasEnproceso();
  }

  ForpagesNumberListEnProceso() {
    let from = this.listEnProceso.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.listEnProceso.total_page) {
      to = this.listEnProceso.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumberEnProceso = pagesArray;
    // return pagesArray;
  }  

  getListVisasAgendado() {
    let params = {
      getAgendado: true,
      estado: 1,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.listAgendado = data['obj'];
          this.ForpagesNumberListAgendado();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  changePageListAgendado(page) {
    this.pageAgendado = page; //para el filtro
    this.listAgendado.current = page;
    this.getListVisasAgendado();
  }

  ForpagesNumberListAgendado() {
    let from = this.listAgendado.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.listAgendado.total_page) {
      to = this.listAgendado.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumberAgendado = pagesArray;
    // return pagesArray;
  } 

  getListVisasFinalizado() {
    let params = {
      getFinalizado: true,
      estado: 1,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          console.log(data);
          this.listFinalizado = data['obj'];
          this.ForpagesNumberListFinalizado();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  changePageListFinalizado(page) {
    this.pageFinalizado = page; //para el filtro
    this.listFinalizado.current = page;
    this.getListVisasFinalizado();
  }

  ForpagesNumberListFinalizado() {
    let from = this.listFinalizado.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.listFinalizado.total_page) {
      to = this.listFinalizado.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumberFinalizado = pagesArray;
    // return pagesArray;
  } 

  verVisa(item) {
    console.log(item);
    this.VisaSeleccionada = item;
    this.visa_id = this.VisaSeleccionada.id
    this.cliente_celular = this.VisaSeleccionada.cliente_celular
    this.cliente_id = this.VisaSeleccionada.cliente_id
    this.adjunto_nombre = '';
    this.detalle_historial = '';
    this.personas = item.personas;
    this.valor_servicio = item.valor_servicio;
    this.valor_total = item.valor_total;
    this.foto = item.foto;
    this.pasaporte = item.pasaporte;
    this.formulario = item.formulario;
    this.fecha_cita1 = item.fecha_cita1;
    this.fecha_cita2 = item.fecha_cita2;
    this.estado_solicitud = item.estado_solicitud;
    $('#vervisamodal').modal('show');
    this.getListAdjuntosVisas(item.id);
  }

  calcularTotal() {
    const valorServicio = parseFloat(this.valor_servicio?.toString()) || 0;
    const personas = parseFloat(this.personas?.toString()) || 0;

    // Si el valor de personas es NaN (ejemplo: input vacío), se convierte a 0
    this.valor_total = valorServicio * (isNaN(personas) ? 0 : personas);
  }

  cambiarEstado(estado: number, id: number) {
    let params = {
      editEstado: true,
      id: id,
      estado: estado,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          // console.log(data);
          $('#vervisamodal').modal('hide');
          this.getListVisas();
          this.getListVisasEnproceso();
          this.getListVisasAgendado();
          this.getListVisasFinalizado();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  actualizarVisa(id: number, estado: any) {
    this.estado_solicitud = estado

    let params = {
      editVisa: true,
      id: id,
      personas: this.personas,
      valor_servicio: this.valor_servicio,
      valor_total: this.valor_total,
      foto: this.foto,
      pasaporte: this.pasaporte,
      formulario: this.formulario,
      fecha_cita1: this.fecha_cita1,
      fecha_cita2: this.fecha_cita2,
      estado: this.estado_solicitud,
      ut: this.user.token,
      et: this.user.et,
    };

    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          // console.log(data);
          // $('#vervisamodal').modal('hide');
          this.toastr.success("Solicitud actualizada");
          this.VisaSeleccionada.estado_solicitud = this.estado_solicitud
          this.getListVisas();
          this.getListVisasEnproceso();
          this.getListVisasAgendado();
          this.getListVisasFinalizado();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  sendDetalleVisa(id: number) {
    let params = {
      nuevoDetalleVisa: true,
      id: id,
      cliente_id: this.cliente_id,
      detalle: this.detalle_historial,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          // console.log(data);
          this.detalle_historial = null;

          this.getHistorial(id);
          // this.VisaSeleccionada?.historial.push(this.nuevoActividad.value);

          // $('#vervisamodal').modal('hide');
          // this.getListVisas();
          // this.getListVisasEnproceso();
          // this.getListVisasFinalizado();
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }
  getHistorial(id: number) {
    let params = {
      getHistorial: true,
      id: id,
      ut: this.user.token,
      et: this.user.et,
    };
    this.appService.postVisaHistorial(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.VisaSeleccionada.historial = data["obj"];  // Reemplaza el array historial con la respuesta completa
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  getListAdjuntosVisas(visaId) {
    let params = {
      get: true,
      et: this.user.et,
      id: visaId
    };
    this.appService.postVisaAdjuntos(params).subscribe((data) => {
      this.listAdjuntosVisas = data['obj'];
      // console.log(this.listAdjuntos);
      this.cargando = false;
    });
  }

  uploadArchivo(files) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'adjuntos',
          archivo: imgURL
        };
        this.appService.uploadImg(params)
          .subscribe((data) => {
            // console.log(data);

            if (data['logger'] == true) {
              this.adjunto_archivo = data['filename'];
              this.adjunto_archivo_url = data['fileUrl'];
              this.adjunto_archivo_extension = data['extension'];
              this.cargarArchivo();
            }
            this.loadingImg = false;
          });
      }
    }
  }

  cargarArchivo() {
    let params = {
      nuevo: true,
      et: this.user.et,
      ut: this.user.token,
      id: this.visa_id,
      cliente_id: this.cliente_id,
      nombre: this.adjunto_nombre,
      adjunto: this.adjunto_archivo,
      extension: this.adjunto_archivo_extension
    };
    this.appService.postVisaAdjuntos(params).subscribe((data) => {
      // this.listAdjuntos = data['obj'];
      this.getListAdjuntosVisas(this.visa_id);
      this.adjunto_archivo = '';
      this.adjunto_nombre = '';
      this.adjunto_archivo_url = '';
      this.adjunto_archivo_extension = '';
      this.cargando = false;
    });
  }

  deleteAdjunto(item) {
    Swal.fire({
      title: '¿Está seguro?',
      html: 'Desea eliminar el archivo <b>' + item.nombre_archivo + ' adjunto</b> ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          eliminar: true,
          et: this.user.et,
          id: item.id
        };
        this.appService.postVisaAdjuntos(params).subscribe((data) => {
          // this.listAdjuntos = data['obj'];
          this.cargando = false;
          this.getListAdjuntosVisas(this.visa_id);
        });

      }
    });
  }


  uploadArchivoFormato(files) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'adjuntos',
          archivo: imgURL
        };
        console.log(params);
        this.appService.uploadImg(params)
          .subscribe((data) => {
            // console.log(data);

            if (data['logger'] == true) {
              this.adjunto_archivo = data['filename'];
              this.adjunto_archivo_url = data['fileUrl'];
              this.adjunto_archivo_extension = data['extension'];
              // this.cargarArchivo();
              this.editForm.get('formulario').setValue(data['filename']);
              this.editForm.get('formulario_url').setValue(data['fileUrl']);

              console.log(data);
            }
            this.loadingImg = false;
          });
      }
    }
  }  

}
