import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gatekeeper } from 'gatekeeper-client-sdk';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    // public static get baseURL():string{ return"http://localhost:8888/apirest-faroappv2/"};
    // public static get baseURL(): string { return "https://api.faroapp.com.co/" };
    public static get baseURL(): string { return "https://api.aplicacionfaro.com/" };


    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient) { }


    // ESTADISTICAS    
    getEstadisticas(params) {
        return this.http.get(AppService.baseURL + 'status/' + params);
    }


    // EMPRESAS    
    getEmpresas(params) {
        return this.http.get(AppService.baseURL + 'empresa/' + params);
    }

    postEmpresa(params) {
        return this.http.post(AppService.baseURL + 'empresa/', params);
    }

    // USUARIOS    
    getUsuarios(params) {
        return this.http.get(AppService.baseURL + 'usuario/' + params);
    }

    postUsuario(params) {
        return this.http.post(AppService.baseURL + 'usuario/', params);
    }

    // CLIENTES    
    getClientes(params) {
        return this.http.get(AppService.baseURL + 'cliente/' + params);
    }

    postClientes(params) {
        return this.http.post(AppService.baseURL + 'cliente/', params);
    }

    // POLITICAS    
    getPoliticas(params) {
        return this.http.get(AppService.baseURL + 'politica/' + params);
    }

    postPoliticas(params) {
        return this.http.post(AppService.baseURL + 'politica/', params);
    }

    // PLANTILLA    
    getPlantillas(params) {
        return this.http.get(AppService.baseURL + 'plantilla/' + params);
    }

    postPlantillas(params) {
        return this.http.post(AppService.baseURL + 'plantilla/', params);
    }    

    // SOLICITUDES    
    getSolicitudes(params) {
        return this.http.get(AppService.baseURL + 'solicitud/' + params);
    }

    postSolicitudes(params) {
        return this.http.post(AppService.baseURL + 'solicitud/', params);
    }

    // PROVEEDORES
    getProveedor(params) {
        return this.http.get(AppService.baseURL + 'proveedor/' + params);
    }

    postProveedor(params) {
        return this.http.post(AppService.baseURL + 'proveedor/', params);
    }

    // PROVEEDOR ACTIVIDADES

    getProveedorActividad(params) {
        return this.http.get(AppService.baseURL + 'proveedor_actividad/' + params);
    }

    postProveedorActividad(params) {
        return this.http.post(AppService.baseURL + 'proveedor_actividad/', params);
    }

    // CODIGOS IATA    
    getIata(params) {
        return this.http.get(AppService.baseURL + 'codigo_iata/' + params);
    }

    postIata(params) {
        return this.http.post(AppService.baseURL + 'codigo_iata/', params);
    }


    // PASAJEROS
    getPasajeros(params) {
        return this.http.get(AppService.baseURL + 'pasajero/' + params);
    }

    postPasajeros(params) {
        return this.http.post(AppService.baseURL + 'pasajero/', params);
    }

    // TARJETA RESERVA    
    getTarjetaReserva(params) {
        return this.http.get(AppService.baseURL + 'tarjeta_reserva/' + params);
    }

    postTarjetaReserva(params) {
        return this.http.post(AppService.baseURL + 'tarjeta_reserva/', params);
    }

    // TARJETA RESERVA PASAJEROS    
    postTarjetaReservaPasajero(params) {
        return this.http.post(AppService.baseURL + 'tarjeta_reserva_pasajero/', params);
    }

    // TARJETA RESERVA VUELOS    
    postTarjetaReservaVuelo(params) {
        return this.http.post(AppService.baseURL + 'tarjeta_reserva_vuelo/', params);
    }

    // TARJETA RESERVA VUELOS    
    postTarjetaReservaActividad(params) {
        return this.http.post(AppService.baseURL + 'tarjeta_reserva_actividad/', params);
    }

    // TARJETA RESERVA COSTO    
    postTarjetaReservaCosto(params) {
        return this.http.post(AppService.baseURL + 'tarjeta_reserva_costo/', params);
    }

    // TARJETA RESERVA HISTORIAL DE ABONO    
    postReservaAbono(params) {
        return this.http.post(AppService.baseURL + 'tarjeta_reserva_abono/', params);
    }

    // TARJETA RESERVA ADJUNTOS    
    postTarjetaReservaAdjuntos(params) {
        return this.http.post(AppService.baseURL + 'tarjeta_reserva_adjuntos/', params);
    }

    // GALERIA MEDIOS
    getGaleriaMedios(params) {
        return this.http.get(AppService.baseURL + 'galeria_medios/' + params);
    }

    postGaleriaMedios(params) {
        return this.http.post(AppService.baseURL + 'galeria_medios/', params);
    }

    // COTIZACIONES
    getCotizacion(params) {
        return this.http.get(AppService.baseURL + 'cotizacion/' + params);
    }

    postCotizacion(params) {
        return this.http.post(AppService.baseURL + 'cotizacion/', params);
    }

    // COTIZACION ITEM COSTEO
    getCotizacionItemCosteo(params) {
        return this.http.get(AppService.baseURL + 'cotizacion_item_costeo/' + params);
    }

    postCotizacionItemCosteo(params) {
        return this.http.post(AppService.baseURL + 'cotizacion_item_costeo/', params);
    }    
    
    //  VISADO
    getVisado() {
        return this.http.get(AppService.baseURL + 'visa/');
    }

    postVisado(params) {
        return this.http.post(AppService.baseURL + 'visa/', params);
    }

    //  VISA
    getVisa() {
        return this.http.get(AppService.baseURL + 'visa/');
    }

    postVisa(params) {
        return this.http.post(AppService.baseURL + 'visa/', params);
    }

    // FORMATO VISA
    getVisaFormato(params) {
        return this.http.get(AppService.baseURL + 'visa_formato/'+ params);
    }

    postVisaFormato(params) {
        return this.http.post(AppService.baseURL + 'visa_formato/', params);
    }


    postVisaHistorial(params) {
        return this.http.post(AppService.baseURL + 'visa_historial/', params);
    }    


    postVisaAdjuntos(params) {
        return this.http.post(AppService.baseURL + 'visa_adjuntos/', params);
    }    


    // UPLOAD IMG
    uploadImg(params) {
        return this.http.post(AppService.baseURL + 'upload_img/', params);
    }

    //  OTROS
    async loginByAuth({ email, password }) {
        try {
            const token = await Gatekeeper.loginByAuth(email, password);
            // console.log(token);            
            // const token = "fasdfasdfadfafdafasf";
            localStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({ email, password }) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            // await this.getProfile();
            this.router.navigate(['/']);
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async Login({ email, password }) {
        let params = {
            lg: true,
            email: email,
            password: password,
        };
        //   console.log(params);
        this.http.post(AppService.baseURL + 'login', params)
            .subscribe((data) => {
                if (data["logger"] == true) {
                    // console.log(data);
                    const token = data["token"];
                    localStorage.setItem('token', token);
                    this.user = data;
                    this.router.navigate(['/']);
                    this.toastr.success(data["msg"]);
                } else {
                    this.toastr.error(data["msg"]);

                }
            });
        // return this.http.post(AppService.baseURL+'login',params);
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
        this.toastr.success('Sesión Cerrada');
    }
}
