<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Operaciones</h1>
            </div>
            <!-- /.col -->
            <!-- <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard v1</li>
                </ol>
            </div> -->
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
            <div class="col-lg-3 col-6" *ngIf="user.perfil =='15kwqgk3123'">
                <!-- small box -->
                <div class="small-box bg-body" [routerLink]="['/empresas']">
                    <div class="inner">
                        <h3>{{estadisticas.countEmpresa}}</h3>
                        <p>Empresas</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-building"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='15kwqgk3123'">
                <!-- small box -->
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3>{{estadisticas.countUsuariosAll}}</h3>
                        <p>Usuarios Registrados</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-body" [routerLink]="['/solicitudes']">
                    <div class="inner">
                        <h3>1</h3>
                        <p>Solicitudes hoy</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-calendar-check"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>
            
            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3>1</h3>
                        <p>Visas</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-passport"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3>{{estadisticas.countUsuariosAll}}</h3>
                        <p>Usuarios</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users-cog"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div> 

            <div class="col-lg-3 col-6" *ngIf="user.perfil =='2fk6i2o3143'">
                <!-- small box -->
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3>{{estadisticas.count_clientes}}</h3>
                        <p>Clientes</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>  
            
            <div class="col-lg-3 col-6" *ngIf="user.perfil =='3af3s12f153'">
                <!-- small box -->
                <div class="small-box bg-body">
                    <div class="inner">
                        <h3>{{estadisticas.count_clientes}}</h3>
                        <p>Clientes</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>              

            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-body">
                    <div class="inner">
                        <h5><a [routerLink]="['/solicitud/']+user.esigla" target="_blank">URL solicitudes</a></h5>
                        <p>Formulario público</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-link"></i>
                    </div>
                    <div class="small-box-footer"></div>
                </div>
            </div>              
                        
        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->
