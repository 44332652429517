<style>
  .container-fluid {
    /* background: rgb(33,150,243);
  background: radial-gradient(circle, rgba(33,150,243,1) 0%, rgba(33,95,243,1) 50%, rgba(69,120,252,1) 100%); */
    background-color: #fff !important;

    background-image: url('assets/img/bg1.png');
    background-size: cover;

    /* background-color: #18468e !important; */
  }

  .container {
    background-color: #2196f3 !important;
  }
</style>
<div class="container-fluid pt-0 pb-3">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10">
      <div>
        <img *ngIf="tarjeta_reserva?.archivo_url" [src]="tarjeta_reserva?.archivo_url" class="img-fluid w-100">
      </div>
      <div class="card">
        <div [style.backgroundColor]="tarjeta_reserva?.empresa_color_principal" class="row m-0">
          <h1 class="col-sx-12 col-md-5">
            <span *ngIf="tarjeta_reserva?.empresa_logo">
              <img class="mt-3 img-fluid" [src]="tarjeta_reserva.empresa_logo" />
          </span>
            <!-- {{tarjeta_reserva?.empresa?.nombre}} -->
          </h1>
          <div class="col-sx-12 col-md-7" style="color: white;">
            <div class="font-weight-bold" style="font-size: 1.4em;">Tarjeta de Reserva #{{tarjeta_reserva?.consecutivo}}</div>
            <div class="font-weight-bold" style="font-size: 1.2em;">{{tarjeta_reserva?.empresa?.direccion | titlecase}}
            </div>
            <div>
              <small style="font-size: 1.1em;">Celular: {{tarjeta_reserva?.empresa?.telefono | titlecase}}</small>
            </div>
            <small style="font-size: 1.1em;">Email: {{tarjeta_reserva?.empresa?.email | titlecase}}</small>
          </div>
        </div>
        <hr>
        <div class="table-responsive m-2">
          <table class="table table-borderless">
            <tbody>
              <tr class="">
                <td>
                  <h5><i class="fa fa-file-invoice-dollar"></i> Facturar a</h5>
                </td>
                <td></td>
              </tr>
              <tr class="content">
                <td class="font-weight-bold">Nombre: {{tarjeta_reserva?.cliente?.nombre | titlecase}} <br>Dirección:
                  {{tarjeta_reserva?.cliente?.direccion | titlecase}} <br>Cedula: {{tarjeta_reserva?.cliente?.cc |
                  titlecase}}</td>
                <td class="font-weight-bold">Telefono: {{tarjeta_reserva?.cliente?.telefono | titlecase}} <br>Celular:
                  {{tarjeta_reserva?.cliente?.celular | titlecase}} <br>Email: {{tarjeta_reserva?.cliente?.email |
                  titlecase}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <div class="table-responsive products p-2">
          <h5 class="add ml-1"><i class="fa fa-users"></i> Viajeros</h5>
          <table class="table table-borderless">
            <tbody>
              <tr class="add">
                <td>#</td>
                <td>Nombre y Apellidos</td>
                <td>Identificación</td>
                <td class="text-center">ADL/Niño</td>
                <td>Fecha Nac.</td>
              </tr>
              <tr class="content" *ngFor="let item of tarjeta_reserva?.pasajeros let i=index">
                <td>{{i+1}}</td>
                <td>{{item.nombre | titlecase}}</td>
                <td>{{item.cc}}</td>
                <td class="text-center">{{item.adulto}}</td>
                <td>{{item.fecha_nacimiento}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <div class="table-responsive products p-2">
          <h5 class="add ml-1"><i class="fa fa-plane-departure"></i> Tus Vuelos</h5>
          <table class="table table-borderless">
            <tbody>
              <tr class="add">
                <td>#</td>
                <td>CIA</td>
                <td>VUELO</td>
                <td>DESDE</td>
                <td>HASTA</td>
                <td>DIA</td>
                <td>MES</td>
                <td>SALE</td>
                <td>LLEGA</td>
                <td>RECORD</td>
              </tr>
              <tr class="content" *ngFor="let item of tarjeta_reserva?.vuelos let i=index">
                <td>{{i+1}}</td>
                <td>{{item.cia | titlecase}}</td>
                <td>{{item.vuelo}}</td>
                <td>{{item.origen}}</td>
                <td>{{item.destino}}</td>
                <td>{{item.dia}}</td>
                <td>{{item.mes}}</td>
                <td>{{item.sale}}</td>
                <td>{{item.llega}}</td>
                <td>{{item.clase}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <div class="products p-2">

          <div class="products p-2">
            <div class="products p-2">
              <div class="row">
                <div class="col-12">
                  <div class="row mb-4">
                    <div class="col-md-4 col-xs-12">
                      <h5 class="add"><i class="fa fa-h-square"></i> TU ALOJAMIENTO</h5>
                    </div>
                    <div class="col-md-8 col-xs-12">
                      <h5 class="ml-0">{{tarjeta_reserva?.hotel | uppercase}}</h5>
                    </div>
                    
                    <div class="col-md-4 col-xs-12 mt-4">
                      <h5 class=""><i class="fa fa-plane-arrival"></i> Fecha llegada</h5>
                      <div class="">{{tarjeta_reserva?.fecha_llegada}}</div>
                    </div>
                    
                    <div class="col-md-4 col-xs-12 mt-4">
                      <h5 class=""><i class="fa fa-plane-departure"></i> Fecha salida</h5>
                      <div class="">{{tarjeta_reserva?.fecha_out}}</div>
                    </div>

                    <div class="col-md-4 col-xs-12 mt-4">
                      <h5 class=""><i class="fa fa-bed"></i> Noches</h5>
                      <div><h5>{{noches}}</h5></div>
                    </div>
                  </div>

                  <hr>
                </div>
              </div>
            </div>


            <div>

            </div>

            <!-- plantilla -->
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="text-center mb-2">
                    <h5 class="add">Tu Habitación</h5>
                  </div>
                </div>
                <hr>

                <div class="row" *ngFor="let item of tarjeta_reserva?.acomodacion; let i=index">
                  <div *ngIf="item.tipo ==1" class="col-md-3 col-xs-12 mt-2">
                    <button class="btn btn-sm btn-block btn-primary" disabled="disabled"><i class="fa fa-user"></i>
                      <h6>Sencilla</h6>
                    </button>
                  </div>
                  <div *ngIf="item.tipo ==2" class="col-md-3 col-xs-12 mt-2">
                    <button class="btn btn-sm btn-block btn-secondary" disabled="disabled"><i
                        class="fa fa-user-friends"></i>
                      <h6>Doble</h6>
                    </button>
                  </div>
                  <div *ngIf="item.tipo ==3" class="col-md-3 col-xs-12 mt-2">
                    <button class="btn btn-sm btn-block btn-warning" disabled="disabled"><i class="fa fa-users"></i>
                      <h6>Triple</h6>
                    </button>
                  </div>
                  <div *ngIf="item.tipo ==4" class="col-md-3 col-xs-12 mt-2">
                    <button class="btn btn-sm btn-block btn-info" disabled="disabled"><i class="fa fa-dice-four"></i>
                      <h6>Cuádruple</h6>
                    </button>
                  </div>
                  <div class="col-9 mt-2">
                    <div class="table-responsive">
                      <table class="table-borderless">
                        <tbody>
                          <tr *ngFor="let itemPasajero of item.pasajeros; let iP=index">
                            <td style="min-width: 20px;"><b>{{iP+1}}</b> </td>
                            <td>{{itemPasajero.nombre | titlecase}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr>
                </div>
              </div>
            </div>

          </div>
          <hr>

          <div class="products p-2">
            <h5 class="add ml-1"><i class="fa fa-parachute-box"></i> PROVEEDORES</h5>
            <table class="table table-borderless">
              <tbody>
                <tr class="add">
                  <td>#</td>
                  <td>PROVEEDOR</td>
                  <td>ACTIVIDAD</td>
                </tr>
                <tr class="content" *ngFor="let item of tarjeta_reserva?.proveedores let i=index">
                  <td>{{i+1}}</td>
                  <td>{{item.proveedor | titlecase}}</td>
                  <td>{{item.nombre}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr>


          <div class="products p-2">
            <table class="table table-borderless">
              <tbody>
                <tr class="add">
                  <td>Valor Venta</td>
                  <td class="">Tipo Pasajero</td>
                  <td class="text-right">Valor</td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Adulto ({{tarjeta_reserva?.adulto}})</td>
                  <td class="text-right" style="font-size: 1.5em;">$ <span
                      *ngIf="tarjeta_reserva?.adulto_tarifa">{{tarjeta_reserva?.adulto_tarifa | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.adulto_tarifa">0</span></td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Niño ({{tarjeta_reserva?.nino}})</td>
                  <td class="text-right" style="font-size: 1.5em;">$ <span
                      *ngIf="tarjeta_reserva?.nino_tarifa">{{tarjeta_reserva?.nino_tarifa | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.nino_tarifa">0</span></td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Infante ({{tarjeta_reserva?.infante}})</td>
                  <td class="text-right" style="font-size: 1.5em;">$ <span
                      *ngIf="tarjeta_reserva?.infante_tarifa">{{tarjeta_reserva?.infante_tarifa | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.infante_tarifa">0</span></td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td class="text-right">Total</td>
                  <td class="text-right" style="font-size: 1.8em;">$ <span *ngIf="tarjeta_reserva?.total_venta"
                      class="text-bold">{{tarjeta_reserva?.total_venta | decimal}}</span> <span
                      *ngIf="!tarjeta_reserva?.total_venta" class="text-bold">0</span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr>

          <div class="products p-2">
            <h5 class="add ml-1"><i class="fa fa-file-upload"></i> ADJUNTOS</h5>
            <table class="table table-borderless">
              <tbody>
                <tr class="add">
                  <td>#</td>
                  <td>Archivo Adjunto</td>
                  <td class="text-center">Extensión</td>
                </tr>
                <tr class="content" *ngFor="let item of tarjeta_reserva?.adjuntos let i=index" style="cursor: pointer;">
                  <td>{{i+1}}</td>
                  <td><a [href]="item.archivo_url" target="_blank">{{item.nombre | titlecase}}</a></td>
                  <td class="text-center"><a [href]="item.archivo_url" target="_blank">{{item.extension | titlecase}}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr>

          <div class="products p-2">
            <h5 class="add ml-1"><i class="fa fa-gavel"></i> POLITICAS</h5>
            <table class="table table-borderless">
              <tbody>
                <!-- <tr class="add">
                  <td>#</td>
                  <td>POLITICA</td>
                </tr> -->
                <tr class="content" *ngFor="let item of tarjeta_reserva?.politicas let i=index" style="cursor: pointer;" (click)="politicaVer(item)">
                  <td>{{i+1}}</td>
                  <td>{{item.nombre | titlecase}}</td>
                </tr>
              </tbody>
            </table>
          </div>


          <hr>
          <div class="table-responsive">
            <table class="table table-borderless mt-4">
              <tbody>
                <tr class="add">
                  <td><i class="fa fa-ambulance"></i> Contacto Emergencia</td>
                  <td>Nombre</td>
                  <td>Contacto</td>
                </tr>
                <tr class="content">
                  <td></td>
                  <td>{{tarjeta_reserva?.contacto_nombre | titlecase}}</td>
                  <td>{{tarjeta_reserva?.contacto_celular}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <hr>
              <div class="address p-2">
                  <table class="table table-borderless">
                      <tbody>
                          <tr class="add">
                              <td>Bank Details</td>
                          </tr>
                          <tr class="content">
                              <td> Bank Name : ADS BANK <br> Swift Code : ADS1234Q <br> Account Holder : Jelly Pepper <br> Account Number : 5454542WQR <br> </td>
                          </tr>
                      </tbody>
                  </table>
              </div> -->
        </div>
      </div>
    </div>
  </div>




  <div class="modal fade" id="politicaVer" tabindex="-1" aria-labelledby="politicaVerLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Politica</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#politicaVer"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mb-4 mt-4">{{politicaNombre | titlecase}}</h1>
            <div>{{politicaDescripcion}}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>